import React from "react";
import ordenarFechasEvento from "../../helpers/ordenarFechasEvento";
import moment from "moment";
import "moment/locale/es";

const CabeceraInterna = ({ evento, variablesEntorno }) => {
  moment.locale("es");

  const eventoOrdenado = ordenarFechasEvento(evento);

  const {
    Titulo = "no disponible",
    tipo_evento,
    Lugar,
    ImagenDestacada,
    CostoPersonalizado,
    Costo,
    LinkInscripcion,
    agenda_master_dirigido_as,
    Fechas,
    Descripcion,
  } = eventoOrdenado;

  const proximaFecha = Fechas[0];

  let calendarInicio = null;
  let calendarFin = null;
  if (proximaFecha.Fecha) {
    calendarInicio = moment(
      moment(proximaFecha.Fecha).format("YYYY-MM-DD ") + proximaFecha.Inicio
    )
      // .utc()
      .format("YYYYMMDDTHHmmss");

    calendarFin = moment(
      moment(proximaFecha.Fecha).format("YYYY-MM-DD ") + proximaFecha.Fin
    )
      .add(1, "day")
      // .utc()
      .format("YYYYMMDDTHHmmss");
  } else {
    calendarInicio = moment(proximaFecha.Inicio)
      // .utc()
      // .toISOString()
      .format("YYYYMMDDTHHmmss");

    calendarFin = moment(proximaFecha.Fin)
      .add(1, "day")
      // .utc()
      .format("YYYYMMDDTHHmmss");
  }
  //cag 20230210 agregando imagen x defecto
  const ImagenPorDefecto = (ImagenDestacada)?null: {url: `${process.env.GATSBY_AGENDA_IMAGE_DEFAULT}`};
  console.log("dentro event card ImagenDestacada : " + JSON.stringify(ImagenPorDefecto));

  return (
    <div className="sect-presentacion">
      <div className="container-1200 destacado-interno w-container">
        <div className="div-50 interna-text">
          <div className="div-block-2">
            {tipo_evento && tipo_evento.Nombre && (
              <div className="tipo-label">{tipo_evento.Nombre}</div>
            )}
            <h1 className="heading h2-slider-home">{Titulo}</h1>
            <div className="img-detacado-sm">
              {ImagenDestacada && (
                <img
                  src={`${variablesEntorno.AGENDA_APP_SITE}${ImagenDestacada.url}`}
                  loading="lazy"
                  alt="Imagen ejemplo Aula Magna"
                />
              )}
            </div>

            {Fechas.length > 1 ? (
              <div>
                <div className="wrap-txt-destacado">
                  <div className="text-field-interno">FECHAS:</div>
                  {Fechas.map((f, i) => (
                    <div key={i} className="text-details">
                      {f.Fecha
                        ? moment(f.Fecha).format("D [de] MMMM [del] YYYY") +
                          " de " +
                          `${moment(
                            moment(f.Fecha).format("YYYY-MM-DD ") + f.Inicio
                          ).format("h:mm a")} a ${moment(
                            moment(f.Fecha).format("YYYY-MM-DD ") + f.Fin
                          ).format("h:mm a")}
                      `
                        : f.Inicio && f.Fin
                        ? `Del ${moment(f.Inicio).format(
                            "D [de] MMMM [del] YYYY"
                          )} al ${moment(f.Fin).format(
                            "D [de] MMMM [del] YYYY"
                          )}` +
                          " de " +
                          `${moment(f.Inicio).format("h:mm a")} a ${moment(
                            f.Fin
                          ).format("h:mm a")}`
                        : "Fecha Invalida"}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              Fechas.map((f, i) => {
                return (
                  i < 3 && (
                    <div key={i}>
                      <div className="wrap-txt-destacado">
                        <div className="text-field-interno">FECHA:</div>
                        <div className="text-details">
                          {f.Fecha
                            ? moment(f.Fecha).format("D [de] MMMM [del] YYYY")
                            : f.Inicio && f.Fin
                            ? `Del ${moment(f.Inicio).format(
                                "D [de] MMMM [del] YYYY"
                              )} al ${moment(f.Fin).format(
                                "D [de] MMMM [del] YYYY"
                              )}`
                            : "Fecha Invalida"}
                        </div>
                      </div>
                      <div className="wrap-txt-destacado">
                        <div className="text-field-interno">HORA:</div>
                        <div>
                          {f.Fecha
                            ? `${moment(
                                moment(f.Fecha).format("YYYY-MM-DD ") + f.Inicio
                              ).format("h:mm a")} a ${moment(
                                moment(f.Fecha).format("YYYY-MM-DD ") + f.Fin
                              ).format("h:mm a")}`
                            : f.Inicio && f.Fin
                            ? `${moment(f.Inicio).format("h:mm a")} a ${moment(
                                f.Fin
                              ).format("h:mm a")}`
                            : "Fecha Invalida"}
                        </div>
                      </div>
                    </div>
                  )
                );
              })
            )}

            {Lugar.map((l, i) => {
              if (l.__component === "lugar.externo") {
                return (
                  l.Ubicacion && (
                    <div key={i} className="wrap-txt-destacado">
                      <div className="text-field-interno">LUGAR:</div>
                      <div>{l.Ubicacion}</div>
                      {/* <a href="#" className="link-destacado-interno">
                      ver ubicacion
                    </a> */}
                    </div>
                  )
                );
              } else if (l.__component === "lugar.google-maps") {
                return (
                  l.DescripcionLugar &&
                  l.URL && (
                    <div key={i} className="wrap-txt-destacado">
                      <div className="text-field-interno">LUGAR:</div>
                      <div>{l.DescripcionLugar}</div>
                      <a
                        href={l.URL}
                        target="_blank"
                        rel="noreferrer"
                        className="link-destacado-interno"
                      >
                        ver ubicacion
                      </a>
                    </div>
                  )
                );
              } else {
                return (
                  <div key={i} className="wrap-txt-destacado">
                    <div className="text-field-interno">LUGAR:</div>
                    <div>{(l.agenda_master_lugar_pucp && l.agenda_master_lugar_pucp.Nombre)?l.agenda_master_lugar_pucp.Nombre:""}</div>
                    {/* <button type="button" className="link-destacado-interno">
                      ver ubicacion
                    </button> */}
                  </div>
                );
              }
            })}

            {(CostoPersonalizado || Costo) && (
              <div className="wrap-txt-destacado">
                <div className="text-field-interno">INVERSIóN:</div>
                {CostoPersonalizado ? (
                  <div>{CostoPersonalizado}</div>
                ) : (
                  <>
                    {Costo === "GratuitoIngresoLibre" && (
                      <div>Ingreso libre</div>
                    )}
                    {Costo === "GratuitoIngresoConRegistro" && (
                      <div>Ingreso libre previo registro</div>
                    )}
                  </>
                )}
              </div>
            )}

            {agenda_master_dirigido_as.length > 0 && (
              <div className="wrap-txt-destacado">
                <div className="text-field-interno">DIRIGIDO A:</div>
                <div>
                  {agenda_master_dirigido_as.map(
                    (d, i) =>
                      `${d.Nombre}${
                        i + 1 < agenda_master_dirigido_as.length ? " - " : ""
                      }`
                  )}
                </div>
              </div>
            )}
            <div className="div-botones-desta">
              {LinkInscripcion && (
                <a
                  href={LinkInscripcion}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-blue mr-15 inscription w-button"
                >
                  inscribirme
                </a>
              )}
              <a
                href={`https://www.google.com/calendar/render?action=TEMPLATE&text=${Titulo}&details=${Descripcion}&location=${Lugar.map(
                  (l) =>
                    `${
                      l.Ubicacion
                        ? l.Ubicacion
                        : l.DescripcionLugar
                        ? l.DescripcionLugar
                        : l.agenda_master_lugar_pucp
                        ? l.agenda_master_lugar_pucp.Nombre
                          ? l.agenda_master_lugar_pucp.Nombre
                          : ""
                        : ""
                    }`
                )}&dates=${calendarInicio}%2F${calendarFin}`}
                target="_blank"
                rel="noreferrer"
                className="btn-blue outline-white mr-15 w-button"
              >
                agregar a google calendar
              </a>
              {/* <button
                type="button"
                className="btn-blue outline-white share w-button"
              >
                share
              </button> */}
            </div>
          </div>
        </div>
        <div className="div-50 interna-img-destacado">
        {ImagenPorDefecto && (
            <img
              src={`${variablesEntorno.AGENDA_APP_SITE}${ImagenPorDefecto.url}`}
              loading="lazy"
              alt="Imagen ejemplo Aula Magna"
              className="img-destacado"
            />
          )}
          {ImagenDestacada && (
            <img
              src={`${variablesEntorno.AGENDA_APP_SITE}${ImagenDestacada.url}`}
              loading="lazy"
              alt="Imagen ejemplo Aula Magna"
              className="img-destacado"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CabeceraInterna;